<template>
  <LayoutMainPages>
    <div class="max-w-sm xl:max-w-full">
      <Title :title="$tc('navigation.support')" />
      <div class="flex flex-col mt-7-5 md:mt-4">
        <div>
          <div class="font-semibold mt-4">
            {{$t('support.live_chat')}}
          </div>
          
          <div class="font-semibold mt-4">
            {{$t('support.our_live_chat')}}
          </div>

          <div class="font-semibold mt-4">
            {{$t('support.feel_free')}}
          </div>

          <div class="font-semibold mt-4">
            {{$t('support.you_should_expect')}}
          </div>
        </div>

        <a class="text-center font-bold text-text-positive mt-7-5" href="https://help.dibbs.io/" target="_blank" rel="noopener noreferrer">
          {{$t('support.go_to_help_center')}}
        </a>

        <div @click="openChat" class="text-center font-bold text-text-positive mt-7-5 cursor-pointer">
          {{$t('support.open_live_chat')}}
        </div>
      </div>
    </div>
  </LayoutMainPages>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { LayoutMainPages, Title } from '@/components/misc';

export default {
  name: 'Support',
  components: {
    LayoutMainPages,
    Title,
  },

  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'SUPPORT_VIEWED',
    });

    if (this.isAuthenticated()) {
      this.$intercom.boot({
        user_id: this.account_id,
        name: this.getUserFullName,
        email: this.getUserEmail,
      });
    } else {
      this.$intercom.boot();
    }
  },

  beforeDestroy() {
    this.$intercom.shutdown();
  },

  computed: {
    ...mapGetters('user', ['getUserEmail', 'getUserFullName']),
    ...mapState('user', ['account_id']),
  },

  methods: {
    openChat() {
      this.$intercom.show();
    },
  },
};
</script>

<style scoped>
</style>
